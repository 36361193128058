import { Map, VectorSourceSpecification, SymbolLayerSpecification, LineLayerSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';
import { OnGetFeatureFromLatLng } from '../fetcher/fetcher';

export const ID = 'limites_administrativos.subprefeituras';

namespace sources {
  export const POLYGON_ID = 'subprefeituras-source';
  export const CENTROID_ID = 'label_subprefeituras-source';
  export const POLYGON_VECTORSOURCELAYER = 'limites_administrativos.subprefeituras';
  export const CENTROID_VECTORSOURCELAYER = 'limites_administrativos.v_subprefeituras_centroid';

  export const POLYGON = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POLYGON_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;

  export const CENTROID = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace colors {
  export const DEFAULT = '#909090';
}

namespace layers {
  export const POLYGON = {
    'id': 'subprefeituras-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': colors.DEFAULT,
      'line-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const LABEL = {
    'id': 'subprefeituras-label',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': ['concat', 'SUBPREFEITURA\n', ['get', 'sp_nome']],
      'text-max-width': 14,
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // zoom 10 (ou menor):
        10,
        10,
        // zoom 13 (ou maior):
        13,
        14,
      ],
      'text-anchor': 'center',
      'text-transform': 'uppercase',
      'visibility': 'none',
    },
    'paint': {
      'text-color': colors.DEFAULT,
      'text-halo-width': 2,
      'text-halo-color': 'white',
    },
  } as SymbolLayerSpecification;
}

namespace Fetcher {
  export async function GetFeatureFromLatLng(
    lat: number,
    lng: number,
  ): Promise<{ geojson: GeoJSON.Feature; error: Error }> {
    const getFeature = await OnGetFeatureFromLatLng('get_subprefeitura_from_latlng');
    return await getFeature(lat, lng);
  }
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLYGON_ID, sources.POLYGON);
  map.addSource(sources.CENTROID_ID, sources.CENTROID);

  map.addLayer(layers.POLYGON);
  map.addLayer(layers.LABEL);

  return { id: ID, layersIds: [layers.POLYGON.id, layers.LABEL.id] };
}

export { Fetcher };
