import {
  Map,
  FillLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'cadernos_drenagem.manchas_tr_025';
namespace colors {
  export const HOVER = '#3B82F6';
  export const ACTIVE = '#2563EB';
  export const FILL = '#9BD4EC';
  export const OUTLINE = '#7180A8';
  export const LABEL = '#000000';
}
namespace sources {
  export const PERTO_ID = 'manchas_inundacao_025-perto-source';
  export const LONGE_ID = 'manchas_inundacao_025-source';
  export const LONGE_VECTORSOURCELAYER = 'cadernos_drenagem.v_manchas_2024_08_22_tr_025_dissolved_by_bacia';
  export const PERTO_VECTORSOURCELAYER = 'cadernos_drenagem.v_manchas_2024_08_22_tr_025';

  export const PERTO = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${PERTO_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;

  export const LONGE = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${LONGE_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace layers {
  export const LONGE_FILL = {
    'id': 'manchas_inundacao_025-layer-longe',
    'source': sources.LONGE_ID,
    'source-layer': sources.LONGE_VECTORSOURCELAYER,
    'type': 'fill',
    'paint': {
      'fill-color': colors.FILL,
      'fill-opacity': 0.5,
      'fill-outline-color': colors.OUTLINE,
    },
    'layout': {
      visibility: 'none',
    },
  } as FillLayerSpecification;

  export const PERTO_FILL = {
    'id': 'manchas_inundacao_025-layer-perto',
    'source': sources.PERTO_ID,
    'source-layer': sources.PERTO_VECTORSOURCELAYER,
    'type': 'fill',
    'minzoom': 17,
    'paint': {
      'fill-color': colors.FILL,
      'fill-opacity': 0.5,
      'fill-outline-color': colors.OUTLINE,
    },
    'layout': {
      visibility: 'none',
    },
  } as FillLayerSpecification;

  export const PERTO_LABEL = {
    'id': 'manchas_inundacao_025-layer-label',
    'source': sources.PERTO_ID,
    'source-layer': sources.PERTO_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': 17,
    'layout': {
      'visibility': 'none',
      'text-font': ['Ubuntu Regular'],
      'text-field': [
        'number-format',
        ['to-number', ['get', 'prof_max'], 0], // input
        { 'locale': 'pt-BR', 'min-fraction-digits': 1, 'max-fraction-digits': 1 }, // options
      ],
      'text-size': 10,
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        colors.ACTIVE,
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LABEL, //normal
      ],
      'text-halo-width': 2,
      'text-halo-color': 'white',
    },
  } as SymbolLayerSpecification;

  export const ACTIVE_PERTO_FILL = {
    'id': 'manchas_inundacao_025-active-layer',
    'source': sources.PERTO_ID,
    'source-layer': sources.PERTO_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        colors.ACTIVE,
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent', //normal
      ],
      'line-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.LONGE_ID, sources.LONGE);
  map.addSource(sources.PERTO_ID, sources.PERTO);

  map.addLayer(layers.LONGE_FILL);
  map.addLayer(layers.PERTO_FILL);
  map.addLayer(layers.PERTO_LABEL);
  map.addLayer(layers.ACTIVE_PERTO_FILL);

  return {
    id: ID,
    layersIds: [layers.LONGE_FILL.id, layers.PERTO_FILL.id, layers.PERTO_LABEL.id, layers.ACTIVE_PERTO_FILL.id],
  };
}

const INTERACTIVE_LAYERS_IDS: string[] = [layers.PERTO_LABEL.id, layers.PERTO_FILL.id];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.PERTO_LABEL];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [layers.PERTO_LABEL, layers.ACTIVE_PERTO_FILL];

export { INTERACTIVE_LAYERS_IDS, HIGHLIGHT_ON_HOVER_LAYERS, HIGHLIGHT_ON_CLICK_LAYERS };
