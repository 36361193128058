import {
  CircleLayerSpecification,
  FillLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  Map,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'protecao_defesa_civil.area_risco_hidrologico_inativo';

namespace sources {
  export const POLIGONOS_ID = 'area_risco_hidrologico_poligonos_inativos';
  export const CENTROID_ID = 'area_risco_hidrologico_centroid_inativos';
  export const POLIGONOS_VECTORSOURCELAYER = 'protecao_defesa_civil.v_area_risco_hidrologico';
  export const CENTROID_VECTORSOURCELAYER = 'protecao_defesa_civil.v_area_risco_hidrologico_centroid';

  export const POLIGONOS_PERTO = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.POLIGONOS_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;

  export const POLIGONOS_LONGE = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const LABEL = '#4A4947';

  export const OUTLINE = '#C0C6C8';
  export const FILL = 'transparent';
}

namespace layers {
  const ZOOM_POLIGONOS_THRESHOLD = 13;

  export const POLIGONOS_PERTO = {
    'id': 'poligonos_perto_area_risco_hidrologico_inativo-layer',
    'source': sources.POLIGONOS_ID,
    'source-layer': sources.POLIGONOS_VECTORSOURCELAYER,
    'type': 'line',
    'filter': ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': ZOOM_POLIGONOS_THRESHOLD,
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        'transparent',
        colors.OUTLINE,
      ],
      'line-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const POLIGONOS_PERTO_FILL = {
    'id': 'poligonos_perto_area_risco_hidrologico_inativo-fill-layer',
    'source': sources.POLIGONOS_ID,
    'source-layer': sources.POLIGONOS_VECTORSOURCELAYER,
    'type': 'fill',
    'filter': ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': ZOOM_POLIGONOS_THRESHOLD,
    'paint': {
      'fill-color': [
        'case',
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
        colors.FILL,
        'transparent',
      ],
      'fill-opacity': 0.4,
      'fill-outline-color': 'black',
    },
    'layout': {
      visibility: 'none',
    },
  } as FillLayerSpecification;

  export const POLIGONOS_LONGE = {
    'id': 'poligonos_longe_area_risco_logico_inativo-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': 0,
    'maxzoom': ZOOM_POLIGONOS_THRESHOLD,
    'layout': {
      'text-font': ['Arial Unicode MS Bold'],
      'text-field': '●',
      'text-size': 12,
      'text-anchor': 'center',
      'text-offset': [0, 0],
      'text-allow-overlap': true,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
        colors.OUTLINE,
        'transparent',
      ],
      'text-halo-color': 'white',
      'text-halo-width': 3,
    },
  } as SymbolLayerSpecification;

  export const POLIGONOS_PERTO_LABEL = {
    'id': 'poligonos_area_risco_hidrologico_inativo-label',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': ZOOM_POLIGONOS_THRESHOLD,
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': [
        'concat',
        ['get', 'sg_area_risco_hidrologico'],
        '\n',
        ['get', 'nm_area_risco_hidrologico'],
        '\n(inativo)',
      ],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'center',
      'text-offset': [0, 0],
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LABEL,
      ],
      'text-halo-color': 'white',
      'text-halo-width': 2,
    },
  } as SymbolLayerSpecification;

  export const POLIGONOS_LONGE_LABEL = {
    'id': 'longe_poligonos_area_risco_hidrologico_inativo-label',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': 0,
    'maxzoom': ZOOM_POLIGONOS_THRESHOLD,
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': ['concat', ['get', 'sg_area_risco_hidrologico'], '\n(inativo)'],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.3],
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LABEL,
      ],
      'text-halo-color': 'white',
      'text-halo-width': 2,
    },
  } as SymbolLayerSpecification;

  export const GAMBI = {
    // Gambiarra estranha: com o layer abaixo, o hover do label funciona. Sem,
    // o hover do label não funciona. Repare que o layer abaixo não tem nem
    // circle-radius. Mas, se for removido, o hover do label não funciona.
    'id': 'gambi_area_risco_hidrologico_inativo-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'filter': ['==', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 0,
    },
  } as CircleLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLIGONOS_ID, sources.POLIGONOS_PERTO);
  map.addSource(sources.CENTROID_ID, sources.POLIGONOS_LONGE);

  const LAYERS_TO_ADD = [
    layers.POLIGONOS_LONGE,
    layers.POLIGONOS_PERTO,
    layers.POLIGONOS_PERTO_FILL,
    layers.POLIGONOS_PERTO_LABEL,
    layers.POLIGONOS_LONGE_LABEL,
    layers.GAMBI,
  ];
  LAYERS_TO_ADD.map(layer => map.addLayer(layer));

  return {
    id: ID,
    layersIds: LAYERS_TO_ADD.map(layer => layer.id),
  };
}

const INTERACTIVE_LAYERS_IDS: string[] = [
  layers.POLIGONOS_PERTO.id,
  layers.POLIGONOS_LONGE.id,
  layers.POLIGONOS_PERTO_LABEL.id,
  layers.POLIGONOS_LONGE_LABEL.id,
];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [
  layers.POLIGONOS_PERTO,
  layers.POLIGONOS_LONGE,
  layers.POLIGONOS_PERTO_LABEL,
  layers.POLIGONOS_LONGE_LABEL,
];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [
  layers.POLIGONOS_PERTO,
  layers.POLIGONOS_LONGE,
  layers.POLIGONOS_PERTO_LABEL,
  layers.POLIGONOS_LONGE_LABEL,
];

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
