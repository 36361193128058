import { Map, SymbolLayerSpecification, VectorSourceSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'infraestrutura_urbana.postos_telemetricos';

namespace sources {
  export const POINT_ID = 'postos_telemetricos_2023_03';
  export const POINT_VECTORSOURCELAYER = 'infraestrutura_urbana.postos_telemetricos_2023_03';

  export const POINT = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POINT_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace layers {
  export const POINT = {
    'id': 'postos_telemetricos_2023_03-layer',
    'source': sources.POINT_ID,
    'source-layer': sources.POINT_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': 0,
    'maxzoom': 22,
    'layout': {
      'text-font': ['Arial Unicode MS Bold'],
      'text-field': '▲',
      'text-size': 20,
      'text-anchor': 'center',
      'text-offset': [0, 0],
      'text-allow-overlap': true,
      'visibility': 'none',
    },
    'paint': {
      'text-color': '#008000',
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  } as SymbolLayerSpecification;

  export const LABEL = {
    'id': 'postos_telemetricos_2023_03-label',
    'source': sources.POINT_ID,
    'source-layer': sources.POINT_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': 0,
    'maxzoom': 22,
    'layout': {
      'text-font': ['Ubuntu Bold'],
      'text-field': ['concat', 'POSTO\n', ['get', 'name']],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.5],
      'text-allow-overlap': true,
      'visibility': 'none',
    },
    'paint': {
      'text-color': '#008000',
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  } as SymbolLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POINT_ID, sources.POINT);

  map.addLayer(layers.POINT);
  map.addLayer(layers.LABEL);

  return { id: ID, layersIds: [layers.POINT.id, layers.LABEL.id] };
}
