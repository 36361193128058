import { Map, VectorSourceSpecification, CircleLayerSpecification, SymbolLayerSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'infraestrutura_urbana.galeria_executada_no';
export const MIN_ZOOM = 14;

namespace sources {
  export const POINT_ID = 'galeria_executada_no_2022_08_22';
  export const POINT_VECTORSOURCELAYER = 'infraestrutura_urbana.galeria_executada_no_2022_08_22';

  export const POINT = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POINT_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace layers {
  export const POINT = {
    'id': 'galeria_executada_no_2022_08_22-layer',
    'source': sources.POINT_ID,
    'source-layer': sources.POINT_VECTORSOURCELAYER,
    'minzoom': MIN_ZOOM,
    'maxzoom': 22,
    'type': 'circle',
    'paint': {
      'circle-radius': 3,
      'circle-color': '#1D4ED8',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 2,
    },
    'layout': {
      visibility: 'none',
    },
  } as CircleLayerSpecification;

  export const LABEL = {
    'id': 'galeria_executada_no_2022_08_22-label',
    'source': sources.POINT_ID,
    'source-layer': sources.POINT_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': MIN_ZOOM,
    'maxzoom': 22,
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': 'Nó',
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.5],
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'visibility': 'none',
    },
    'paint': {
      'text-color': '#1D4ED8',
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  } as SymbolLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POINT_ID, sources.POINT);
  map.addLayer(layers.POINT);
  map.addLayer(layers.LABEL);
  return { id: ID, layersIds: [layers.POINT.id, layers.LABEL.id] };
}
