import { Map, LineLayerSpecification, SymbolLayerSpecification, VectorSourceSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'infraestrutura_urbana.galeria_executada_rede';
export const MIN_ZOOM = 14;

namespace sources {
  export const LINE_ID = 'galeria_executada_rede_2022_08_22';
  export const LINE_VECTORSOURCELAYER = 'infraestrutura_urbana.galeria_executada_rede_2022_08_22';
  export const LINE = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${LINE_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace layers {
  export const LINE = {
    'id': 'galeria_executada_rede_2022_08_22-layer',
    'source': sources.LINE_ID,
    'source-layer': sources.LINE_VECTORSOURCELAYER,
    'minzoom': MIN_ZOOM,
    'maxzoom': 22,
    'type': 'line',
    'paint': {
      'line-color': '#1D4ED8',
      'line-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  const tipoCanal = ['get', 'tipo_canal'];
  const material = ['case', ['==', ['to-string', ['get', 'material']], ''], 'material nd', ['get', 'material']];
  const diametro = ['get', 'diametro'];
  const formaSeca = ['case', ['==', ['to-string', ['get', 'forma_seca']], ''], 'forma nd', ['get', 'forma_seca']];
  const largura = ['get', 'largura'];
  export const LABEL = {
    'id': 'galeria_executada_rede_2022_08_22-label',
    'source': sources.LINE_ID,
    'source-layer': sources.LINE_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': MIN_ZOOM,
    'maxzoom': 22,
    'layout': {
      'symbol-placement': 'line-center',
      'text-font': ['Arial Unicode MS Regular'],
      'text-field': ['concat', tipoCanal, '\n', formaSeca, ' · ', material, ' · ⌀=', diametro, ' · L=', largura],
      'text-size': 12,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      // 'text-offset': [0, 0],
      'visibility': 'none',
    },
    'paint': {
      'text-color': '#1D4ED8',
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  } as SymbolLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.LINE_ID, sources.LINE);
  map.addLayer(layers.LINE);
  map.addLayer(layers.LABEL);
  return { id: ID, layersIds: [layers.LINE.id, layers.LABEL.id] };
}
